import React from 'react';
import { graphql } from 'gatsby';

import Wrapper from '../components/Wrapper';
import Page from '../components/Page';

const singleProduct = ({ data, location }) => {

  const { sanityProduct } = data;

  return (
    <Wrapper seo={{seoSettings: sanityProduct.seoSettings, title: sanityProduct.title }}  location={location}>
      <Page {...data.sanityProduct} product />
    </Wrapper>
  );
};

export const ProductQuery = graphql`
    query productQuery($slug: String!) {
    sanityProduct(slug: {current: {eq: $slug}}) {
      id
      title
      mainImage {
        alt
        asset {
          fluid(maxWidth: 800) {
            ...GatsbySanityImageFluid
          }
        }
      }
      _rawDescription
      buyButton {
        code
      }
      seoSettings {
        metaDescription
        titleTag
      }
    }
  }
`;

export default singleProduct;